.taxTitle{
    font-family: var(--normal);
    text-align:left;
}
.taxContainer{
    display:flex;
    padding:1rem 0;
    gap:1rem;
    background-color: var(--lightBlue);
}
.taxContainer img{
    height:25rem;
}
p{
    font-family: var(--normal);
}
.taxContainer .link, p{
    font-size:1.2rem;
    color:var(--bBlack);
    margin:0;
}
.taxContainer .link:hover{
    color:white;
}
.requirementsContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:2rem 0rem;
}
.requirementsContainer h1{
    font-size:3rem;
    font-family: var(--bebas);
}
.requirementsContainer>*{
    font-family: var(--normal);

}
.bullet{
    display:flex;
    height:4rem;
    align-items: center;
    font-size:1.5rem;
}
.bullets{
    gap:1rem;
    display:flex;
    flex-direction: column;
    align-items: start;
}
.bulletImg{
    height:100%;
    margin-right:1rem;
    
}
.textSection{
    display:flex;
    flex-direction: column;
    justify-content: center;
}

.flex{
    display:flex;
    align-items: center;
    justify-content: center;
    max-width: 1000px;
    width:100%;
    margin:0 auto;
}
@media (max-width:1000px){
    .taxContainer{
        align-items:center;
        flex-direction: column;
    }
    .center, .taxContainer a img, .flex{
        width: 700px;
    }
    .taxContainer a img, .center, .flex{
            max-width:100%;
    }
    .bullets{
        gap:4rem;
    }
    .flex .bullets{
        gap:2rem;
    }

}
@media (max-width:700px){
    .center, .taxContainer a img, .flex{
        width:400px;
    }
}