:root{
    --bentoBackground:var(--periwinkle);
}

.row{
    flex-direction: column;
}
.introContainer .flex div h1{
    font-family:var(--normal);
}
.introContainer{
    position: relative;
    padding:3rem 0;
}
.introContainer img{
    height:10rem;
}
.introContainer .flex div p{
    font-size: 1.4rem;
}
.bento{
    display:grid;
    width:100%;
    max-width:700px;
    position:relative;

    margin-left: auto;
    margin-right: auto;
    gap:0.5vw;
    grid-template-columns: repeat(6,1fr);
    grid-template-rows:1fr min-content;
    grid-template-areas:
    "title title title title mech mech"
    "water water window window mech mech"
    "light light window window foam foam"
    "solar solar solar solar solar solar ";
}
.bento>*{
    background:var(--bentoBackground);
    border-radius:2rem;
    padding:1rem;
}
.bento a{
    position: relative;
    display: flex;
    justify-content: center;

    align-items: center;
}
.bento a>*{
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding:0.25rem;
    align-items: center;
    

}
.bento a div p{
    font-size:1.3rem;
    text-align: center;
}
.bento a div>img{
    max-height:40%;
    max-width:40%;
}
.bentoTitle{
    grid-area:title;
    font-family: var(--normal);
    font-size:2rem;
    padding-left:1rem;
    font-weight: bold;

}
.mechanicalBento{
    grid-area: mech;

}
.waterBento{
    grid-area:water;
}
.foamBento{
    grid-area:foam;
}
.windowBento{
    grid-area:window;

}
.lightBulbBento{
    grid-area:light;
}
.solarBento{
    grid-area:solar;
}
.solarBento div{
    flex-direction: row;

}

.bulletGreen{
    display:flex;
    width:100%;
    padding:2rem 0;
    scroll-margin-top: 4rem; /* Adjust this value according to your navbar height */

}
.bulletGreen .bulletImg{
    height:3rem;

}
.bulletGreen .content img{
    height:7rem;
}

.flex div span, .thin{
    width:100%;
    height:0.5rem;
    background-color:var(--bBlack);
    border-radius:25px;
}
.content h1{
    font: size 2.5rem;
    font-family: var(--normal);
}
.content p{
    font-size:1.6rem;
}
@media (max-width:700px){
    .flex{
        flex-direction: column;
    }
    .introContainer .flex div p {
        font-size: 1rem;
    }
}