@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
/* https://coolors.co/d7fdec-a9fbd7-b2e4db-b0c6ce-938ba1 */
:root{
  --periwinkle:#A9FBD7;
  --lightGreen:#D7FDEC;
  --lightBlue:#b2e4db;
  --black:#28262C;
  --bBlack:#000000;
  --darkBlue:#B0C6CE ;
  --bebas : "Bebas Neue", sans-serif;
  --normal:'Avenir', sans-serif;
  --linkColor:var(--darkBlue);
  --section1:var(--lightGreen);
  --section2:white;
  --section3:var(--periwinkle);
  --section4:var(--lightGreen);
}

html {
  scroll-behavior: smooth;
}

.blue{
  color:blue;
}
.pageContent{
  transition: filter 0.3s ease-in-out;
}
*{
  margin:0;
  padding:0;
}
.center{
  max-width: 1000px;
margin-left:auto;
margin-right:auto;}
body{
  color:var(--black);
  background:var(--lightGreen);
  overflow-x:hidden;
}
.mar{
  margin:2rem 0;
  display: block;
}
#earth{
  fill:red;
}
#water{
  fill:red;
}
h1{
  font-family: var(--normal);
}


.Typewriter{
  display:contents;
  width:auto;
  color:var(--darkBlue);
}

.hero{
  display:flex;
  align-items:center;
  margin:0 10%;
  padding: 5% 0;
  
}
.heroContainer{
    background:var(--section1);
  position:relative;

}
.CTAcontainer{
  display:inline-block;
}
.CTA{
  margin-left:auto;
  background:var(--lightBlue);
  border:none;
  display:inline-block;
  white-space:nowrap;
  align-items:center;
  border-radius:28px;
  font-size:50px;
  padding:0.5rem 4rem;
  margin-top: 2rem;
  font-family: "Bebas Neue", sans-serif;
  box-shadow: -20px 10px 0px  var(--darkBlue); 
      transition: all 0.3s ease-in-out;

  
}
.CTA span{
  display:flex;
  align-items:center;
}
.CTA img{
  height:50px;
  margin-top:auto;
  margin-bottom:auto;
}
.CTAcontainer:hover .CTA {
  translate:-20px 10px;
    box-shadow: 0px 0px 0px  var(--lightBlue); 
}



.hero img{
  width:30%;
}
.heroText{
  margin-left:5rem;
  font-size:60px;
  font-family: var(--normal);
  font-weight:bold;
  
}

.wave3 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.wave3 svg {
    position: relative;
    display: block;
    width: calc(155% + 1.3px);
    height: 115px;
    transform: rotateY(180deg);
}

.wave3 .shape-fill {
    fill: var(--section3);
}
.wave1 {
    position: absolute;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.wave1 svg {
    position: relative;
    display: block;
    width: calc(119% + 1.3px);
    height: 145px;
  z-index:5;
}

.wave1 .shape-fill {
    fill: var(--section1);
}

/** For tablet devices **/
@media (min-width: 768px) and (max-width: 1023px) {
    .wave1 svg {
        width: calc(112% + 1.3px);
        height: 145px;
    }
}

/** For mobile devices **/
@media (max-width: 767px) {
    .wave1 svg {
        width: calc(118% + 1.3px);
        height: 137px;
    }
}


/* Card code */
.cardContainer{
  background:var(--section4);
  padding:10rem 5%;
  position:relative;
}
.cardFlex{
  display:flex;
  padding:0.5rem;
  justify-content:space-evenly;
  position:relative;
  height:300vh;
}
.card{
  background:white;
  border:black;
  height:50vh;
  min-width:12rem;
  width:20vw;
  display:flex;
  flex-direction:column;
  align-items:center;
  border:black 1px solid;
  justify-content:center;
/*   Gives a shadow to the boxshadow */
  box-shadow: lightgray -10px -10px, black -10px -10px 0px 0.5px;
  text-align:center;
  /*When the cards are scrolled by they stick to the screen*/
  position:sticky;
  top:5rem;
  padding:1% 0;
  border-radius:20px;
}
.card img{
  max-height:50%;

}
.card p{
  font-size:1.2rem;
  font-family: var(--normal);
  padding-top:1rem;
}

.card:nth-child(2){
  margin-top:60vh;
  top:10rem;
}
.card:nth-child(3){
  margin-top:120vh;
  top:15rem;
}
.underline{
  position:relative;
}
.card a{
  color:var(--linkColor);
    font-family: var(--normal);
  font-size:20px;
  display:inline-block;
  position:relative;
    z-index:7;
  
  
}

.card a::before{
  background:var(--linkColor);
  transform:scaleY(0.1);
  transition:transform 120ms cubic-bezier(0.23, 1, 0.32, 1) 0s, background 120ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
  content:"";
  position:absolute;
    bottom:-0.075em;
  z-index:-1;
  inset:0px;
  transform-origin: center bottom;
  display:block;
}

.card a:hover::before{
  transform:scaleY(1);
}
.card a:hover{
  color:white;
}

.quoteContainer{
  background:var(--section3);
  padding:15rem 20% 5rem;
}

.text{
  font-family: var(--normal);
  font-size:48px;
  line-height:4rem;
  font-weight:normal;
}
.quoteContainer h3{
  font-family: "Bebas Neue", sans-serif;
  text-align:right;
  font-size: 24px;
  margin-right:2rem;
  


}

.quote svg{
  height:20px;
  vertical-align:top;
}

.statement{
  display:flex;
  position:relative;
    justify-content:space-evenly;
  background:#ffffff;
  padding:15rem 15% 5rem;

  
}
.left, .right{
    display:flex; 
  justify-content:left;
}
.right{
  width:75%;
  flex-direction:column;
    padding-right:2rem;

  
}
.left{
  width:25%;
}
.left img{
  width:100%;
}

.right h1{
  font-family: "Bebas Neue", sans-serif;
  font-weight:normal;
  font-size:50px;
  text-align:left;
}
.right p{
    font-family: var(--normal);
  font-size:26px;

}

.wave2 {
    position: absolute;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
  fill:#ffffff;
}
.wave2 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 160px;
}

@media (max-width:1000px){
  .quote .text{
    line-height: 1rem;
    font-size:28px;
  }
  .cardContainer{
    padding-bottom: 0;
  }
  .cardFlex{
    flex-direction: column;
    height: auto;

  }

  .card{
    width:100%;
    max-height: 25vh;
    margin-top:0;
    position: relative;
    top:0;

  }
  .card p{
    font-size:1rem;
  }
  .card:nth-child(2){
    top:0;
    margin-top:1rem;
  }
  .card:nth-child(3){
    top:0;
    margin-top:1rem;
  }

  .earth{
    position:absolute;
  }
  .heroText{
    z-index: 5;
    font-size: 3rem;
    margin:0;
  }
  .CTAcontainer .img{
    width:20%;
  }
  .right p{
    font-size:1.4rem;
  }
  .statement{
    padding-top:10rem;
  }
  .right{
    padding:0;
    width:100%;
  }
  
  .statement{
    flex-direction: column-reverse;

  }
}
.sources{
  font-family: var(--normal);
  font-size:11px;
}
.sources>*{
  font-family: var(--normal);
  font-size:11px;

}