
:root{
    --height:3rem;
    --margin:10%;
}

.title{
  padding-top:1rem;
  font-family:var(--normal);
  padding-left:var(--margin);
}
.programCardContainer{
  min-height: 100vh;
  background-color: var(--lightBlue);
}
.programCards{
  display:grid;
  grid-template-columns: auto auto;  
  grid-gap: 1rem;
  max-width: 1000px;
  padding-top:1rem ;
  flex-wrap:wrap;
  justify-content:space-around;
  padding-bottom: 2rem;
}
@media (max-width:1050px){
  .programCards{
    grid-template-columns: auto;
    max-width:100%;
  }
}
.programCard{
  background: #FAFAFA;
  border-radius:1rem;
  padding:2%;
  box-shadow:#9f9f9fe3 2px 2px 1px 0px;
    font-family: "Nunito Sans", sans-serif;
}
.programCard h1{
  font-weight:normal;
  padding-bottom:0.5rem;
}

.searchContainer{
  padding-left:var(--margin);
    display:flex;
  align-items:center;
  padding-right:var(--margin);
}
.search{
  width:100%;
  justify-content: center;
  border-radius:5rem;
  background:white;
  height:var(--height);
  display:flex;
  padding:0.25rem 2rem 0.25rem 2rem;
  margin:1rem 1% 1rem 0;
    box-shadow:grey 1px 1px 1px;
      width: calc(100% - calc(0.5rem * 3 + 2rem * 3 + 10px * 3));
  align-items:center;
  justify-content:center;
}
.search img{
  height:90%;
}
.search input{
  background:none;
  border:none;
  font-size:2rem;
  width:100%;
  outline: none;
  margin-left:1rem;
}
.icons{
  display:flex;
  width:auto;
}
.icon{
  background:white;
  width:var(--height);
  position:relative;
  height:var(--height);
  border-radius:100px;
  padding:0.25rem;
  display:flex;
  justify-content:center;
  align-items:center;
      box-shadow:grey 1px 1px 1px;
  margin-right:10px;

  
}

.icon img{
  height:85%;
}

 .link{
  color:var(--darkBlue);
    font-family: var(--normal);
  font-size:20px;
  display:inline-block;
  position:relative;
    z-index:7;
  margin-top:0.5rem;
  width: fit-content;
  
}
 .link::before{
  background:var(--darkBlue);
  transform:scaleY(0.1);
  transition:transform 120ms cubic-bezier(0.23, 1, 0.32, 1) 0s, background 120ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
  content:"";
  position:absolute;
    bottom:-0.075em;
  z-index:-1;
  inset:0px;
  transform-origin: center bottom;
  display:block;
}

 .link:hover::before{
  transform:scaleY(1);
}
.programCard .link:hover{
  color:white;
}

.back img{
  height:100%;
}
.back{
  height:1rem;
  display:inline-block;
}
.body{
  min-height:90vh;
  background:white;
    font-family:var(--normal);

}

.back::before{
  inset:4px;
}
.infoTitle{
  font-size:3rem;
}
.tag{
  border-radius:360px;
  height:fit-content;
  width: fit-content;
  display:inline-block;
  padding:0.25rem 0.5rem;
  margin-right:1rem;
  position:relative;
  background:var(--lightBlue);
  transition: background 0.2s ease;
  color:black;
}

.tag::before, .programCard .tag::before {
  display:none;
}

.learnMore{
  position:absolute;
  background:white;
  opacity:0;
  padding:0.5rem;
  top:100%;
  width:7rem;
  box-shadow:rgb(177, 177, 177) 0px 0px 4px;
  display:none;
  z-index:10;
}
.tag:hover .learnMore{
  opacity:1;
  display:block;
}
.programCard .proTag{
  color:var(--black);

}
.infoHeader{
  padding-top:2rem;
  background:var(--periwinkle);
}
.infoGrid{
  display:grid;
  grid-template-columns: 75% 25%;
  grid-gap: 1px;
  margin-top:3rem;
}
.infoInfo{
  border-left:var(--bBlack);
  border-left-style: solid;
  display:flex;
  flex-direction: column;
}
.infoInfo>*{
  margin-left:1rem;
  position: relative;
}
.infoInfo .tag{
  margin-top:0.5rem;
}
.savingsHeader{
  margin-top:1rem;
}
.linkHeader{
  margin-top:1rem;
}
.infoInfo .link{
  margin-top:0;
}
@media (max-width:1000px){
  .infoGrid{
    display:flex;
    flex-direction: column-reverse;
  }
  .infoInfo{
    border-left:none;
    border-bottom: solid;
    margin-bottom:1rem;
    padding-bottom:1rem;
  }
}