@import url('https://fonts.googleapis.com/css2?family=Avenir&display=swap');
/* CSS to be applied on the navigation bar  */


body{
  margin:0;
  min-height:100vh;
}

#root{
  width:100%;
  overflow:clip;
}
nav{
  display:flex;
  width:100%;
  background:white;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
  position:sticky;
  top:0;
  z-index:10;
  height:4rem;
  align-items:center;
  
}
nav ul{
  display:flex;
  margin-left:auto;
  margin-right:8rem;
  list-style-type: none
}
a, li{
  font-family:var(--normal);
  color:var(--black);
  text-decoration:none;
  font-size:1.2rem;  
  position:relative;

}

nav ul a{
  font-size:26px;
  list-style-type:none;
  font-family: 'Avenir', sans-serif;
  margin-right: 2rem;
  text-decoration:none;
  color:var(--black);
}
.active, .footer a{
  color:var(--linkColor);
}
.logo{
  float:left;
  max-height:80%;
  margin-left:3rem;
}
.dropdown{
  position:absolute;
  top:40px;
  height:auto;
  left:0;
  opacity:0;
  transform: translateY(-5%);
  background-color:white;
  border-radius:10px;

  overflow:hidden;
  box-shadow:black -2px -2px;
  flex-direction:column;
    transition:all 1s;
  display:flex;
}
.dropdown a{
  --mar : 1rem;
  margin-left:var(--mar);
  margin-right:var(--mar);
}

.menu-item{
  height:50px;
  display:flex;
  justify-content:center;
}

.footer{
  display:flex;
  background:white;
  width:100%;
  padding-top:1rem;
  position: absolute;
  justify-content:space-around;
}
.footer div ul{
    list-style-type:none;

}

.footer div ul li a{
    list-style-type:none;
    display:inline-block;
  position:relative;

}

.footer div ul li:nth-child(1){
  font-weight:bold;
  font-family:var(--normal);
  padding-bottom:0.5rem;
  
}
nav a::before{
  background:var(--linkColor);
  transform:scaleX(0) scaleY(0.1);
  transition:transform 700ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
  content:"";
  position:absolute;
    bottom:-0.075em;
  height:100%;
  width:100%;
  inset:0px;
  transform-origin: left bottom;
  display:block;
}
nav a:hover::before{
  transform:scaleX(1) scaleY(0.1);
  }
  .none{
    display:none;
  }
  .menu img{
    max-height: 3rem;
    margin:0.5rem 1rem;
    float: right;
    display: none;
  }
  .pageContent{
    filter:none;
  }
@media (max-width:1250px) {
  nav ul{
    position:absolute;
    flex-direction: column;
    right:0;
    top:4rem;
    margin:0;
    height: 100vh;
    min-width:15rem;
    background-color: white;
    padding-left: 1rem;
    transform: translateX(100%);
    transition: transform 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
  }
  nav{
    z-index:10000000;
  }
  nav ul a{
    margin:0;
  }
  nav ul li{
    margin:1rem 0;
    white-space: nowrap;
    display: inline-block;
  }
  .menu{
    position: absolute;
    right:1rem;
  }
  .show{
    transform:none;
  }
  .menu img{
    display: inline-block;
  }
  .menu .none{
    display:none;
  }
  .logo{
    margin-left:1rem;
  }
}
